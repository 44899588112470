/**
 * Created by john on 10/10/16.
 */
'use strict';

/* exported
imagesCtrl
 */

function imagesCtrl($scope, images, appConfig, $location, $uibModal) {
  $scope.images = images || [];
  $scope.filters = {populate: 'owner'};

  $scope.getThumbnailStyle = function(image) {
    var thumbnailUrl = appConfig.imagesServiceBaseUrl + '/media/files/' + image._id + '.' + image.ext + '?width=300';

    return {'background-image': 'url("' + thumbnailUrl + '")'};
  };

  /**
   * Paging
   */
    // On init if page is above 1
  var search = $location.search();

  // On init if type is selected display it
  if (search.type) {
    $scope.filters.type = search.type;
  } else {
    $scope.filters.type = 'picture,template';
  }

  // On init if owner is selected display it
  if (search.owner) {
    $scope.filters.owner = search.owner;
  }

  if (search.page && search.page >= 1) {
    $scope.currentPage = search.page;
  } else {
    $scope.currentPage = 1;
  }

  $scope.totalItems = $scope.images.meta.total;
  $scope.pageChanged = function() {
    var limit = 20;
    var offset = ($scope.currentPage - 1) * 20;

    $scope.filters.offset = offset;
    $scope.filters.limit = limit;

    $scope.images.getList($scope.filters).then(function(images) {
      $scope.images = images;
    });
  };

  $scope.$watch('currentPage', function(page) {
    var search = $location.search();

    if (page > 1) {
      search.page = page;
    } else {
      delete search.page;
    }

    $location.search(search);
  });

  /**
   * Filters
   */
  $scope.$on('filters.owner', function(event, data) {
    $scope.filters.owner = data;
    $scope.filters.limit = 20;
    $scope.filters.offset = 0;

    $scope.images.getList($scope.filters).then(function(images) {

      $scope.currentPage = 1;
      $scope.images = images;
      $scope.totalItems = $scope.images.meta.total;
    });
  });

  $scope.$on('filters.type', function(event, data) {
    if (data === 'picture' || data === 'template') {
      $scope.filters.type = data;
    } else {
      $scope.filters.type = 'picture,template';
    }

    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.images.getList($scope.filters).then(function(images) {
      $scope.currentPage = 1;
      $scope.images = images;
      $scope.totalItems = $scope.images.meta.total;
    });
  });

  $scope.edit = function(image) {
    var approveModalInstance = $uibModal.open({
      templateUrl: 'views/images/outbound.html',
      controller: 'ImageEditCtrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        image: image,
        companies: ['UsersRestangular', function(UsersRestangular) {
          return UsersRestangular.all('companies').getList();
        }]
      }
    });

    approveModalInstance.result.then(function(editedImage) {
      $scope.images.splice($scope.images.indexOf(image), 1, editedImage);
    });
  };
}

/**
 * Created by john on 4/8/17.
 */
'use strict';

/* exported
 menuImagesCtrl
 */
function menuImagesCtrl($scope, $rootScope, $location) {
  $scope.filters = {};

  // On init if company is selected display it
  var search = $location.search();

  if (search.owner) {
    $scope.filters.owner = {_id: search.owner};
  }

  if (search.type) {
    $scope.filters.type = search.type;
  }

  $scope.$watch('filters.owner', function(owner) {
    $rootScope.$broadcast('filters.owner', (owner || {})._id);

    var search = $location.search();
    search.owner = (owner || {})._id;
    $location.search(search);
  });

  $scope.filterByType = function(type) {
    if (type === $scope.filters.type) {
      // Set off filter
      $scope.filters.type = null;
    } else {
      $scope.filters.type = type;
    }

    $rootScope.$broadcast('filters.type', $scope.filters.type);

    var search = $location.search();
    search.type = $scope.filters.type;
    $location.search(search);
  };

  $scope.reset = function() {
    $scope.filters = {};
    $rootScope.$broadcast('filters.owner', null);
    $rootScope.$broadcast('filters.type', null);
    $location.search({});
  };
}

/**
 * Created by john on 10/10/16.
 */
'use strict';

/* exported
uploadImageCtrl
 */

function uploadImageCtrl($scope, user, appConfig, Upload, companies, UsersRestangular, ImagesRestangular, $q, $state) {
  var ownerId = user._id;

  $scope.companies = companies;
  $scope.images = [];

  $scope.getThumbnailStyle = function(image) {
    var thumbnailUrl = appConfig.imagesServiceBaseUrl + '/media/files/' + image._id + '.' + image.ext + '?width=600';

    return {'background-image': 'url("' + thumbnailUrl + '")'};
  };

  $scope.refreshOwners = function(owner) {
    return UsersRestangular.all('companies').getList({name: owner})
      .then(function(companies) {
        $scope.companies = companies;
      });
  };

  // Upload Tickets
  $scope.$watch('pictures', function() {
    $scope.upload($scope.pictures);
  });

  $scope.upload = function (pictures) {
    if (pictures && pictures.length && !pictures.$error) {
      var types = pictures.map(function(picture) {
        return picture.type === 'application/pdf' ? 'template' : 'picture';
      });

      Upload.upload({
        url: appConfig.imagesServiceBaseUrl + '/upload/files',
        arrayKey: '', // http://stackoverflow.com/questions/32917617/multer-not-accepting-files-in-array-format-gives-unexpected-filed-error
        data: {
          owner: ownerId,
          files: pictures,
          types: types
        }
      }).then(function(resp) {
        var images = resp.data;

        images.forEach(function(image) {
          $scope.images.push(image);
          console.log($scope.images);
        });

        $scope.$emit('notify', {type: 'success', title: 'Upload', message: 'Image uploaded with Success'});
      }, function() {
        $scope.$emit('notify', {type: 'error', title: 'Upload', message: 'Failed to upload images'});
      });
    }
  };

  $scope.finish = function() {
    var promises = $scope.images.forEach(function(image) {
      ImagesRestangular.restangularizeElement(null, image, 'files').put();
    });

    $q.all(promises)
      .then(function() {
        $scope.$emit('notify', {type: 'success', title: 'Finish', message: 'Success'});
        $state.go('root.images');
      }, function() {
        $scope.$emit('notify', {type: 'error', title: 'Finish', message: 'Error, try again'});
      });
  };
}

/**
 * Created by john on 6/8/17.
 */
'use strict';

/* exported
imageEditCtrl
 */

function imageEditCtrl($scope, image, appConfig, companies, UsersRestangular, $uibModalInstance) {
  $scope.image = image;
  $scope.companies = companies;

  $scope.getThumbnailStyle = function() {
    var thumbnailUrl = appConfig.imagesServiceBaseUrl + '/media/files/' + $scope.image._id + '.' + $scope.image.ext + '?width=600';

    return {'background-image': 'url("' + thumbnailUrl + '")'};
  };

  $scope.refreshOwners = function(owner) {
    return UsersRestangular.all('companies').getList({name: owner})
      .then(function(companies) {
        $scope.companies = companies;
      });
  };

  $scope.save = function() {
    $scope.isLoading = true;

    $scope.image.put().then(function(editedImage) {
      $scope.$emit('notify', {type: 'success', title: 'Edit', message: 'Image edited with Success'});
      $uibModalInstance.close(editedImage);
      $scope.isLoading = false;
    })
    .catch(function() {
      $scope.$emit('notify', {type: 'error', title: 'Approve', message: 'Booking approve failed'});
      $scope.isLoading = false;
    });
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
}

/**
 * Created by john on 6/8/17.
 */
'use strict';

/* exported
imageChooserCtrl
 */

function imageChooserCtrl($scope, images, appConfig, $uibModalInstance, chosenImages, multiple, type) {
  function computeChosenImages() {
    $scope.images.forEach(function(image ) {
      var isSelected = _.find($scope.chosenImages, function(selectedImage) {
        return selectedImage._id === image._id;
      });

      if (isSelected) {
        image.$$selected = true;
      }
    });
  }

  $scope.images = images;
  $scope.chosenImages = chosenImages;
  $scope.type = type;
  computeChosenImages();
  $scope.filters = {};

  $scope.getThumbnailStyle = function(image) {
    var uri = image.url ? image.url : image._id + '.' + image.ext;
    var thumbnailUrl = appConfig.imagesServiceBaseUrl + '/media/files/' + uri + '?width=300';

    return {'background-image': 'url("' + thumbnailUrl + '")'};
  };

  $scope.getLinkUrl = function(file) {
    var uri = file.url ? file.url : file._id + '.' + file.ext;
    return appConfig.imagesServiceBaseUrl + '/media/files/' + uri;
  };

  /**
   * Paging
   */
  $scope.totalItems = $scope.images.meta.total;
  $scope.currentPage = 1;
  $scope.pageChanged = function() {
    var limit = 20;
    var offset = ($scope.currentPage - 1) * 20;
    var filter = {offset: offset, limit: limit, type: $scope.type, populate: 'owner'};

    if ($scope.filters.owner) {
      filter.owner = $scope.filters.owner;
    }

    $scope.images.getList(filter).then(function(images) {
      $scope.images = images;
      computeChosenImages();
    });
  };

  $scope.$watch('filters.owner', function(owner) {
    var filter = {offset: 0, limit: 20, type: $scope.type, populate: 'owner'};

    if (owner) {
      filter.owner = owner._id;
    }

    $scope.images.getList(filter).then(function(images) {

      $scope.currentPage = 1;
      $scope.images = images;
      computeChosenImages();

      $scope.totalItems = $scope.images.meta.total;
    });
  });

  $scope.toggleSelect = function(image) {
    // Single image
    if (!multiple) {
      if (image.$$selected) {
        $scope.chosenImages = [];
      } else {
        if ($scope.chosenImages.length) {
          $scope.chosenImages[0].$$selected = false;
        }

        $scope.chosenImages = [image];
      }
    } else {
      if (image.$$selected) {
        $scope.chosenImages = $scope.chosenImages.filter(function(selectedImage) {
          return selectedImage._id !== image._id;
        });
      } else {
        $scope.chosenImages.push(image);
      }
    }

    image.$$selected = !image.$$selected;
  };

  $scope.save = function() {
    $scope.$emit('notify', {type: 'success', title: 'Select Images', message: 'Images selected with Success'});
    $uibModalInstance.close($scope.chosenImages);
  };

  $scope.cancel = function() {
    $scope.$emit('notify', {type: 'error', title: 'Select Images', message: 'Closing without selecting images'});
    $uibModalInstance.dismiss('cancel');
  };
}

/**
 * Created by john on 6/8/17.
 */
'use strict';

/* exported
imageChooserDirective
 */

// https://stackoverflow.com/a/6470794/2631086
function arraymove(arr, fromIndex, toIndex) {
  const newArr = [...arr];
  const element = arr[fromIndex];
  newArr.splice(fromIndex, 1);
  newArr.splice(toIndex, 0, element);
  return newArr;
}

function imageChooserDirective($uibModal, appConfig, RideRulesService) {
  return {
    restrict: 'AE',
    require: 'ngModel',
    scope: {
      model: '=ngModel',
      allowMultiple: '=allowMultiple',
      allowMoveimages: '=allowMoveImages',
      mediaType: '@mediaType',
      source: '@'
    },
    templateUrl: 'views/images/chooser.directive.html',
    link: function(scope, iElement, iAttrs, ngModelCtrl) {
      scope.multiple = scope.allowMultiple === undefined ? true : scope.allowMultiple;
      scope.type = scope.mediaType === undefined ? 'picture' : scope.mediaType;
      scope.rideRulesService = RideRulesService;

      // From Model to View
      ngModelCtrl.$formatters.push(function(modelValue) {
        if (modelValue && angular.isArray(modelValue) && modelValue.length) {
          return {chosenImages: modelValue};
        } else if (modelValue && angular.isObject(modelValue) && !angular.isArray(modelValue)) {
          return {chosenImages: [modelValue]};
        } else {
          return {chosenImages: []};
        }
      });

      // From View to Model
      ngModelCtrl.$parsers.push(function(viewValue) {
        var modelValue = viewValue.chosenImages.map(function(chosenImage) {
          return {
            url: chosenImage.url ? chosenImage.url : chosenImage._id + '.' + chosenImage.ext,
            _id: chosenImage._id
          };
        });

        return scope.multiple ? modelValue : modelValue[0];
      });

      // Render
      ngModelCtrl.$render = function() {
        scope.chosenImages = ngModelCtrl.$viewValue.chosenImages;
      };

      scope.shiftImage = function (image, direction) {
        const indexOfImage = scope.chosenImages.findIndex(chosenImage => { return image._id === chosenImage._id });
        const newIndex = direction === 'left' ? indexOfImage - 1 : indexOfImage + 1;
        if (newIndex < 0 || newIndex > scope.chosenImages.length) return;
        scope.chosenImages = arraymove(scope.chosenImages, indexOfImage, newIndex);
        ngModelCtrl.$setViewValue({ chosenImages: scope.chosenImages });
      }

      scope.openChooser = function() {
        var approveModalInstance = $uibModal.open({
          templateUrl: 'views/images/chooser.html',
          controller: 'ImageChooserCtrl',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            images: ['ImagesRestangular', function(ImagesRestangular) {
              return ImagesRestangular.all('files').getList({type: scope.type, populate: 'owner'}).then(function(images) {
                return images;
              });
            }],
            chosenImages: [function() {
              return ngModelCtrl.$viewValue.chosenImages;
            }],
            multiple: [function() {
              return scope.multiple;
            }],
            type: [function() {
              return scope.type;
            }]
          }
        });

        approveModalInstance.result.then(function(chosenImages) {
          scope.chosenImages = chosenImages;
          ngModelCtrl.$setViewValue({chosenImages: chosenImages});
        });
      };
      scope.getMainImageClass= function (image) {
        const firstImageId = scope.chosenImages[0]._id;
        if(firstImageId===image._id) {
          return 'main-image-indicator'
        }
        return '';
      }
      scope.showImageMover = function() {
        return scope.allowMoveimages;
      }
      scope.getThumbnailStyle = function(image) {
        var uri = image.url ? image.url : image._id + '.' + image.ext;
        var thumbnailUrl = appConfig.imagesServiceBaseUrl + '/media/files/' + uri + '?width=300';
        return {'background-image': 'url("' + thumbnailUrl + '")',position:'relative'};
      };

      scope.removeImage = function(image) {
        scope.chosenImages = _.without(scope.chosenImages, function(chosenImage) {
          return chosenImage._id === image._id;
        });

        scope.chosenImages = scope.chosenImages.filter(function(chosenImage) {
          return chosenImage._id !== image._id;
        });

        ngModelCtrl.$setViewValue({chosenImages: scope.chosenImages});
      };

      scope.getLinkUrl = function(file) {
        var uri = file.url ? file.url : file._id + '.' + file.ext;
        return appConfig.imagesServiceBaseUrl + '/media/files/' + uri;
      };
    }
  };
}

'use strict';

/* exported
pdfThumbnailDirective
 */

function pdfThumbnailDirective(PDFJS, ImagesRestangular) {
  return {
    restrict: 'EA',
    scope: {
      pdfUrl: '=',
      thumbnail: '='
    },
    controller: ['$scope', function ($scope) {
      $scope.generateThumbnail = function (file) {
        var reader = new FileReader();

        reader.onload = function(e) {
          var arrayBuffer = e.target.result;
          var uint8Array = new Uint8Array(arrayBuffer);

          PDFJS.disableTextLayer = true;
          PDFJS.getDocument(uint8Array).then(function (_pdfDoc) {
            _pdfDoc.getPage(1).then(function (page) {
              var canvas = angular.element('<canvas></canvas>')[0];

              var viewport = page.getViewport(1.0);
              var ctx = canvas.getContext('2d');

              var viewerWidth = $scope.thumbnailWidth !== 0 ? $scope.thumbnailWidth : $scope.thumbnailElement.width;
              var scale = viewerWidth / viewport.width;

              viewport = page.getViewport(scale);

              canvas.width = viewport.width;
              canvas.height = viewport.height;

              page.render({
                canvasContext: ctx,
                viewport: viewport
              }).then(function () {
                $scope.$apply(function () {
                  var imgDataURL = canvas.toDataURL();
                  $scope.thumbnailElement.style.backgroundImage = 'url(' + imgDataURL + ')';

                  imgDataURL.replace(' ', '+');
                  $scope.thumbnail = imgDataURL;
                });
              });
            });
          });
        };

        reader.readAsArrayBuffer(file);
      };
    }],
    link: function (scope, element, attrs) {
      scope.thumbnailElement = element[0];
      scope.thumbnailWidth = attrs.thumbWidth || 0;

      scope.$watch('pdfUrl', function(pdfUrl) {
        ImagesRestangular
          .one('media/files', pdfUrl)
          .withHttpConfig({responseType: 'blob'})
          .get()
          .then(function(file) {
            scope.file = new Blob([file], {type: 'application/pdf'});
          });
      });

      scope.$watch('file', function () {
        if(scope.file) {
          scope.generateThumbnail(scope.file);
        }
      });
    }
  };
}
